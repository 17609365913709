export const ONEDAYINSECONDS = 86400;
export const frontendurl = window.location.origin;

// export const backurl  = "http://localhost:9374" // local



//  export const backurl   =    "https://backend-genesis.maticz.in" // demo

export const backurl = "https://api.genesis-pt.org" //live

// export const backurl = "https://genesisprotocalapi.maticz.in" //sales




export const BURN = "0x000000000000000000000000000000000000dEaD";

export const BLOCKS_PER_YEAR = 1051200;

export const CHAINS = [

  // {
  //     NAME: "BSC-TEST",
  //     RPC_URL:"https://endpoints.omniatech.io/v1/bsc/testnet/public", // BSC TESTNET
  //     CHAIN_ID:97,

  //     TOKENADDRESS: "0x7933DEC3A4Ccf7304348B2e59F324EFDd7Aa7078", // 
  //     USDTADDRESS : "0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d",
  //     MASTERCHEF: "0xcA491033CDCdE5407F5c7fCFC84209312d0AdBa4", //  BSC NEW TESTNET
  //     LOCKEDSTAKING : "0x020424233dF54347ec037ddF6a3a0CdD58Dc24b5"


  // },


  {
    NAME: "BSC-MAINNET",
    RPC_URL: "https://bsc-dataseed1.binance.org/",
    // https://bsc-dataseed.binance.org/
    CHAIN_ID: 56,
    TOKENADDRESS: "0x21a9119F587Da69b79af7a58FE69a1DbA5Fa3Ce4", //GSP
    USDTADDRESS: "0x55d398326f99059fF775485246999027B3197955", // USDTADDRESS
    NATIVE: "0x21a9119F587Da69b79af7a58FE69a1DbA5Fa3Ce4",
    MASTERCHEF: "0x09b32DFEcd6aa2981fcaf88EE4ec10562023cc62", // launchpad proxy
    LOCKEDSTAKING: "0xC2c6623D422869C76f80A9c8Adc54D252ceE5619" // locked staking
  }

];

